/* You can add global styles to this file, and also import other style files */
html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
tr.mat-header-row {
  background-color: #5F6978 !important;
}
th.mat-header-cell {
  color: #ebb46e !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3N0eWxlcy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLDhFQUFBO0FBRUE7RUFBYSxZQUFBO0FBQ2I7QUFBQTtFQUFPLFNBQUE7RUFBVyxpREFBQTtBQUtsQjtBQUhBO0VBQ0Usb0NBQUE7QUFNRjtBQUhBO0VBQ0UseUJBQUE7QUFNRiIsImZpbGUiOiJzdHlsZXMuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi8qIFlvdSBjYW4gYWRkIGdsb2JhbCBzdHlsZXMgdG8gdGhpcyBmaWxlLCBhbmQgYWxzbyBpbXBvcnQgb3RoZXIgc3R5bGUgZmlsZXMgKi9cblxuaHRtbCwgYm9keSB7IGhlaWdodDogMTAwJTsgfVxuYm9keSB7IG1hcmdpbjogMDsgZm9udC1mYW1pbHk6IFJvYm90bywgXCJIZWx2ZXRpY2EgTmV1ZVwiLCBzYW5zLXNlcmlmOyB9XG5cbnRyLm1hdC1oZWFkZXItcm93IHtcbiAgYmFja2dyb3VuZC1jb2xvcjogIzVGNjk3OCAhaW1wb3J0YW50O1xufVxuXG50aC5tYXQtaGVhZGVyLWNlbGwge1xuICBjb2xvcjogI2ViYjQ2ZSAhaW1wb3J0YW50O1xufVxuIl19 */